import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import "../css/projects.css"
import ProjectsList from "../components/projectslist"

const SecondPage = () => (
  <Layout>
    <SEO title="Iliuta Stoica Projects" />
    <div className="box projectsWrapper">
      <ProjectsList />
    </div>
  </Layout>
)

export default SecondPage
