import React from 'react';

const ProjectsList = (props) => (
<div className="content">
    <div className="grid">
        <div className="grid__item">
            <a className="product" href="https://www.slotscalendar.com/" target="_blank" rel="nofollow noopener noreferrer" data-project="slotscalendar">
                <div className="product__bg"></div>
                <img className="product__img" src="img/projects/1.png" alt="img 01"/>
                <h2 className="product__title">SlotsCalendar</h2>
                <h3 className="product__subtitle">Wordpress </h3>
                <p className="product__description"> SlotsCalendar is an online calendar the latest online casino games. A Wordpress hosted site written in PHP,Wordpress and Javascript with Gitlab Integration. </p>
                <div className="product__price">EveryMatrix</div>
                <span className="product__site">https://www.slotscalendar.com/</span>
            </a>
        </div>
        <div className="grid__item">
            <a className="product" href="https://www.OmegaTipsters.com/" target="_blank" rel="nofollow noopener noreferrer" data-project="slotscalendar">
                <div className="product__bg"></div>
                <img className="product__img" src="img/projects/2.png" alt="img 02"/>
                <h2 className="product__title">OmegaTipsters</h2>
                <h3 className="product__subtitle">Templating</h3>
                <p className="product__description">OmegaTipsters is the biggest online community of PROFESSIONAL TIPSTERS. A Laravel hosted site written in PHP and Javascript with SVN Integration. </p>
                <div className="product__price">EveryMatrix</div>
                <span className="product__site">https://www.OmegaTipsters.com/</span>
            </a>
        </div>
        <div className="grid__item">
            <a className="product" href="https://www.casiplay.com/" target="_blank" rel="nofollow noopener noreferrer" data-project="CasiPlay">
                <div className="product__bg"></div>
                <img className="product__img" src="img/projects/3.png" alt="img 03"/>
                <h2 className="product__title">CasiPlay</h2>
                <h3 className="product__subtitle">Templating</h3>
                <p className="product__description">CasiPlay is an online gambling casino. My part on this project was to transform the design into animated templates with HTML, CSS, Javascript for the integration with VueJS. </p>
                <div className="product__price">Remote</div>
                <span className="product__site">https://www.casiplay.com/</span>
            </a>
        </div>
        <div className="grid__item">
            <a className="product" href="https://www.jetbull.com/" target="_blank" rel="nofollow noopener noreferrer" data-project="Jetbull">
                <div className="product__bg"></div>
                <img className="product__img" src="img/projects/4.png" alt="img 04"/>
                <h2 className="product__title">Jetbull Casino</h2>
                <h3 className="product__subtitle">Templating</h3>
                <p className="product__description">Jetbull Casino is an online gambling casino &amp; sports. My part on this project was to transform the design into animated templates with HTML, CSS, Javascript and integrate them with ASP</p>
                <div className="product__price">EveryMatrix</div>
                <span className="product__site">https://www.jetbull.com/</span>
            </a>
        </div>
        <div className="grid__item">
            <a className="product" href="https://www.betbrain.com/" target="_blank" rel="nofollow noopener noreferrer" data-project="Betbrain">
                <div className="product__bg"></div>
                <img className="product__img" src="img/projects/6.png" alt="img 06"/>
                <h2 className="product__title">Betbrain</h2>
                <h3 className="product__subtitle">Templating</h3>
                <p className="product__description">BetBrain is a Betting Odds Comparison website, with Bookies Offers. My part on this project was to transform the design into animated templates with HTML, CSS, Javascript for the integration with React.</p>
                <div className="product__price">EveryMatrix</div>
                <span className="product__site">https://www.betbrain.com/</span>
            </a>
        </div>
        <div className="grid__item">
            <a className="product" href="https://www.reactcasino.com/" target="_blank" rel="nofollow noopener noreferrer" data-project="ReactCasino">
                <div className="product__bg"></div>
                <img className="product__img" src="img/projects/5.png" alt="img 05"/>
                <h2 className="product__title">React Casino</h2>
                <h3 className="product__subtitle">Templating</h3>
                <p className="product__description">React Casino is an online gambling casino build with React. My part on this project was to transform the design into animated templates with HTML, CSS, Javascript for the integration with React. </p>
                <div className="product__price">EveryMatrix</div>
                <span className="product__site">https://www.reactcasino.com/</span>
            </a>
        </div>
        <div className="grid__item">
            <a className="product" href="https://www.behance.net/gallery/80993247/EveryGame" target="_blank" rel="nofollow noopener noreferrer" data-project="EveryGame">
                <div className="product__bg"></div>
                <img className="product__img" src="img/projects/7.png" alt="img 07"/>
                <h2 className="product__title">EveryGame</h2>
                <h3 className="product__subtitle">Templating</h3>
                <p className="product__description">EveryGame is an online gambling casino. My part on this project was to transform the design into animated templates with Parallax, HTML, CSS, Javascript and integrate them with ASP </p>
                <div className="product__price">EveryMatrix</div>
                <span className="product__site">https://www.behance.net/gallery/80993247/EveryGame</span>
            </a>
        </div>
        <div className="grid__item">
            <a className="product" href="http://profilistled.ro/" target="_blank" rel="nofollow noopener noreferrer" data-project="profilistled">
                <div className="product__bg"></div>
                <img className="product__img" src="img/projects/8.png" alt="img 08"/>
                <h2 className="product__title">Profilistled</h2>
                <h3 className="product__subtitle">Wordpress</h3>
                <p className="product__description">A Wordpress website written in PHP, Wordpress and Javascript with custom animations. Worked in collaboration with the designer Eduarth Schmidt. </p>
                <div className="product__price">Remote</div>
                <span className="product__site">http://profilistled.ro/</span>
            </a>
        </div>
        <div className="grid__item">
            <a className="product" href="https://www.behance.net/gallery/73931279/Caribic-Casino" target="_blank" rel="nofollow noopener noreferrer" data-project="Caribic">
                <div className="product__bg"></div>
                <img className="product__img" src="img/projects/9.png" alt="img 09"/>
                <h2 className="product__title">Caribic Casino</h2>
                <h3 className="product__subtitle">Templating</h3>
                <p className="product__description">Caribic Casino is an online gambling casino. My part on this project was to transform the design into animated templates with Parallax, HTML, CSS, Javascript and integrate them with ASP</p>
                <div className="product__price">EveryMatrix</div>
                <span className="product__site">https://www.behance.net/gallery/73931279/Caribic-Casino</span>
            </a>
        </div>
        <div className="grid__item">
            <a className="product" href="https://www.behance.net/gallery/47957553/WonderlandCasino" target="_blank" rel="nofollow noopener noreferrer" data-project="Wonderland">
                <div className="product__bg"></div>
                <img className="product__img" src="img/projects/10.png" alt="img 10"/>
                <h2 className="product__title">Wonderland Casino</h2>
                <h3 className="product__subtitle">Templating</h3>
                <p className="product__description">Wonderland Casino is an online gambling casino. My part on this project was to transform the design into animated templates with HTML, CSS, Javascript and integrate them with ASP</p>
                <div className="product__price">EveryMatrix</div>
                <span className="product__site">https://www.behance.net/gallery/47957553/WonderlandCasino</span>
            </a>
        </div>
        <div className="grid__item">
            <a className="product" href="http://evente.ro/" target="_blank" rel="nofollow noopener noreferrer" data-project="Evente">
                <div className="product__bg"></div>
                <img className="product__img" src="img/projects/13.png" alt="img 08"/>
                <h2 className="product__title">Evente</h2>
                <h3 className="product__subtitle">Wordpress</h3>
                <p className="product__description">A Wordpress website written in PHP, Wordpress and Javascript with custom animations. Worked in collaboration with the designer Eduarth Schmidt. </p>
                <div className="product__price">Remote</div>
                <span className="product__site">http://evente.ro/</span>
            </a>
        </div>
        <div className="grid__item">
            <a className="product" href="https://www.artemisbet.com/" target="_blank" rel="nofollow noopener noreferrer" data-project="Artemisbet">
                <div className="product__bg"></div>
                <img className="product__img" src="img/projects/11.png" alt="img 10"/>
                <h2 className="product__title">Artemisbet</h2>
                <h3 className="product__subtitle">Templating</h3>
                <p className="product__description">Artemisbet is an online gambling casino &amp; sports. My part on this project was to transform the design into animated templates with HTML, CSS, Javascript and integrate them with ASP</p>
                <div className="product__price">EveryMatrix</div>
                <span className="product__site">https://www.artemisbet.com/</span>
            </a>
        </div>
        <div className="grid__item">
            <a className="product" href="https://www.behance.net/gallery/73930433/ExclusiveClubCasino" target="_blank" rel="nofollow noopener noreferrer" data-project="ExclusiveClubCasino">
                <div className="product__bg"></div>
                <img className="product__img" src="img/projects/12.png" alt="img 10"/>
                <h2 className="product__title">ExclusiveClubCasino</h2>
                <h3 className="product__subtitle">Templating</h3>
                <p className="product__description">ExclusiveClubCasino is an online gambling casino. My part on this project was to transform the design into animated templates with HTML, CSS, Javascript and integrate them with ASP</p>
                <div className="product__price">EveryMatrix</div>
                <span className="product__site">https://www.behance.net/gallery/73930433/ExclusiveClubCasino</span>
            </a>
        </div>
        <div className="grid__item">
            <a className="product" href="http://www.ascensorul.ro/" target="_blank" rel="nofollow noopener noreferrer" data-project="Ascensorul">
                <div className="product__bg"></div>
                <img className="product__img" src="img/projects/14.png" alt="img 08"/>
                <h2 className="product__title">Ascensorul</h2>
                <h3 className="product__subtitle">Wordpress</h3>
                <p className="product__description">A Wordpress website written in PHP, Wordpress and Javascript with custom animations. </p>
                <div className="product__price">Innobyte</div>
                <span className="product__site">http://acensorul.ro/</span>
            </a>
        </div>
        <div className="grid__item">
            <a className="product" href="http://www.securiton.ro/" target="_blank" rel="nofollow noopener noreferrer" data-project="Securiton">
                <div className="product__bg"></div>
                <img className="product__img" src="img/projects/15.png" alt="img 08"/>
                <h2 className="product__title">Securiton</h2>
                <h3 className="product__subtitle">Wordpress</h3>
                <p className="product__description">A Wordpress website written in PHP, Wordpress and Javascript with custom animations. </p>
                <div className="product__price">Innobyte</div>
                <span className="product__site">http://securiton.ro/</span>
            </a>
        </div>
        <div className="grid__item">
            <button className="product" target="_blank" rel="nofollow noopener noreferrer" data-project="Atessa">
                <div className="product__bg"></div>
                <img className="product__img" src="img/projects/16.png" alt="img 08"/>
                <h2 className="product__title">Atessa</h2>
                <h3 className="product__subtitle">Wordpress, Design</h3>
                <p className="product__description">A Wordpress website written in PHP, Wordpress with custom animations. This theme was designed by me in 2010. </p>
                <div className="product__price">Ascensys</div>
                <span className="product__site">#</span>
            </button>
        </div>
        <div className="grid__item">
            <button className="product"  target="_blank" rel="nofollow noopener noreferrer" data-project="xxx">
                <div className="product__bg"></div>
                <img className="product__img" src="img/projects/17.png" alt="img 08"/>
                <h2 className="product__title">Love or Above</h2>
                <h3 className="product__subtitle">Wordpress, Design</h3>
                <p className="product__description">A Wordpress website written in PHP, Wordpress with custom animations. This theme was designed by me in 2010. </p>
                <div className="product__price">Ascensys</div>
                <span className="product__site">#</span>
            </button>
        </div>
        <div className="grid__item">
            <button className="product" target="_blank" rel="nofollow noopener noreferrer" data-project="xxx">
                <div className="product__bg"></div>
                <img className="product__img" src="img/projects/18.png" alt="img 08"/>
                <h2 className="product__title">Garden of Life</h2>
                <h3 className="product__subtitle">Wordpress</h3>
                <p className="product__description">A Wordpress website written in PHP, Wordpress with custom animations. This theme was designed by me in 2010. </p>
                <div className="product__price">Ascensys</div>
                <span className="product__site">#</span>
            </button>
        </div>
        <div className="grid__item">
            <button className="product" target="_blank" rel="nofollow noopener noreferrer" data-project="xxx">
                <div className="product__bg"></div>
                <img className="product__img" src="img/projects/19.png" alt="img 08"/>
                <h2 className="product__title">Octagon</h2>
                <h3 className="product__subtitle">Wordpress, Design</h3>
                <p className="product__description">A Wordpress website written in PHP, Wordpress with custom animations. This theme was designed by me in 2010. </p>
                <div className="product__price">Ascensys</div>
                <span className="product__site">#</span>
            </button>
        </div>
        <div className="grid__item">
            <button className="product" target="_blank" rel="nofollow noopener noreferrer" data-project="xxx">
                <div className="product__bg"></div>
                <img className="product__img" src="img/projects/20.png" alt="img 08"/>
                <h2 className="product__title">SportsHut</h2>
                <h3 className="product__subtitle">Wordpress, Design</h3>
                <p className="product__description">A Wordpress website written in PHP, Wordpress with custom animations. This theme was designed by me in 2010. </p>
                <div className="product__price">Ascensys</div>
                <span className="product__site">#</span>
            </button>
        </div>
        <div className="grid__item">
            <button className="product" target="_blank" rel="nofollow noopener noreferrer" data-project="xxx">
                <div className="product__bg"></div>
                <img className="product__img" src="img/projects/21.png" alt="img 08"/>
                <h2 className="product__title">Web Access</h2>
                <h3 className="product__subtitle">Wordpress, Design</h3>
                <p className="product__description">A Wordpress website written in PHP, Wordpress with custom animations. This theme was designed by me in 2010. </p>
                <div className="product__price">Remote</div>
                <span className="product__site">#</span>
            </button>
        </div>
    </div>
</div>

)

export default ProjectsList